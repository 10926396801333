import { EnvConst } from 'consts/commonConst'

// 川崎パイロット日常点検環境
export const envConst: EnvConst = {
  ENVIRONMENT: 'kp-daily',
  DATASET_ID: 3322286356517968,
  FACILITY_NAME: '川崎パイロット',
  BACKEND_API_ROOT_URL:
    'https://kg2elizbyf.execute-api.ap-northeast-1.amazonaws.com',
  CDF_PROJECT: 'ak-h2'
}
